<template>
  <ValidationObserver ref="observer" tag="form" autocomplete="off">
    <slot></slot>
  </ValidationObserver>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    formKey: {
      type: String,
      required: true
    },
    entity: {
      type: Object,
      required: true
    },
    input: {
      type: Object,
      required: true
    }
  },
  methods: {
    beforeunload(event) {
      // フォーム毎に検知し、破棄のメッセージを表示するように変更
      // if (this.$store.getters["form/isAnyDirty"]) {
      if (this.$store.getters["form/isDirty"](this.formKey)) {
        // ダイアログのメッセージは、ブラウザ依存(変更不可)
        event.returnValue = "入力内容を破棄します。よろしいですか。";
      }
    },
    isEmptyValue(input) {
      return input === "" || input === null || input === undefined;
    },
    isDirty(input, entity) {
      switch (Object.prototype.toString.call(input)) {
        case "[object Object]":
          return Object.keys(input).some(key => {
            return this.isDirty(
              input[key],
              entity === undefined ? undefined : entity[key]
            );
          });

        case "[object Array]":
          if (input.length !== entity.length) {
            return true;
          }

          if (Object.prototype.toString.call(input[0]) === "[object Object]") {
            // object配列
            return input.some((value, index) => {
              return this.isDirty(
                value,
                entity === undefined ? undefined : entity[index]
              );
            });
          } else {
            return JSON.stringify(input) !== JSON.stringify(entity);
          }

        default:
          if (this.isEmptyValue(input) && this.isEmptyValue(entity)) {
            return false;
          }

          return JSON.stringify(input) !== JSON.stringify(entity);
      }
    },
    watcher() {
      const { getters, commit } = this.$store;
      const isDirty = this.isDirty(this.input, this.entity);

      if (getters["form/isDirty"](this.formKey) !== isDirty) {
        commit("form/changeDirty", [
          {
            key: this.formKey,
            isDirty: isDirty
          }
        ]);
      }
    },
    validate() {
      return this.$refs.observer.validate();
    },
    setErrors(errors) {
      this.$refs.observer.setErrors(errors);
    }
  },
  watch: {
    entity: {
      handler() {
        this.watcher();
      },
      deep: true
    },
    input: {
      handler() {
        this.watcher();
      },
      deep: true
    }
  },
  mounted() {
    window.addEventListener("beforeunload", this.beforeunload);
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.beforeunload);
  }
};
</script>

<template>
  <v-container fluid :class="$style['container']">
    <v-overlay :value="processing">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <ToolBar :class="$style['container-header']" :title="title" />
    <div :class="$style['container-content']">
      <template v-if="error">
        <div :class="$style['container-error']">
          <p>
            <v-icon color="red darken-2">mdi-alert-circle</v-icon>
            <span>エラーが発生しました</span>
          </p>
          <v-btn @click="onBack">戻る</v-btn>
        </div>
      </template>
      <template v-else>
        <MyForm
          :formKey="'base-form'"
          :entity="entity"
          :input="input"
          ref="form"
        >
          <FieldGenerator
            v-for="(field, index) in fields"
            v-model="input[field.name]"
            :key="index"
            :type="field.type"
            :name="field.name"
            :label="field.label"
            :optionType="field.optionType"
            :textKey="field.textKey"
            :valueKey="field.valueKey"
            :multiple="field.multiple"
            :limit="field.limit"
            :rules="field.rules"
          >
            <template v-if="field.type === 'custom'" :slot="field.name">
              <slot
                :name="field.name"
                :input.sync="input[field.name]"
                :error-messages="errorMessages[field.name]"
              />
            </template>
          </FieldGenerator>
        </MyForm>
      </template>
    </div>
    <div v-if="!error" :class="$style['container-footer']">
      <v-toolbar>
        <v-btn class="mx-1" color="primary" @click="onCreate">
          <v-icon left>mdi-plus-box</v-icon>登録
        </v-btn>
        <v-btn class="mx-1" @click="onBack">
          <v-icon left>mdi-cancel</v-icon>キャンセル
        </v-btn>
      </v-toolbar>
    </div>
  </v-container>
</template>

<script>
import MyForm from "@/entries/front/components/form";
import FieldGenerator from "@/entries/front/components/field/generator";
import ToolBar from "@/entries/front/components/app/toolbar";

export default {
  components: {
    MyForm,
    FieldGenerator,
    ToolBar
  },
  props: {
    title: {
      type: String,
      required: true
    },
    resourceName: {
      type: String,
      required: true
    },
    fields: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      input: {}
    };
  },
  computed: {
    entity() {
      return this.$store.getters[`${this.resourceName}/entity/entity`];
    },
    processing() {
      return this.$store.getters[`${this.resourceName}/entity/isProcessing`];
    },
    error() {
      return this.$store.getters[`${this.resourceName}/entity/error`];
    },
    errorMessages() {
      return (
        this.$store.getters[`${this.resourceName}/entity/errorMessages`] || []
      );
    }
  },
  methods: {
    onCreate() {
      if (!this.$refs.form) {
        return;
      }

      this.$refs.form.validate().then(success => {
        if (!success) {
          return;
        }

        this.$store
          .dispatch(`${this.resourceName}/entity/store`, {
            storedEntity: this.input
          })
          .then(() => {
            this.$router.push({ name: this.resourceName });
          })
          .catch(() => {
            if (this.$refs.form) {
              this.$refs.form.setErrors(this.errorMessages);
            }
          });
      });
    },
    onBack() {
      this.$router.push({ path: `/${this.resourceName}` });
    }
  },
  created() {
    this.$store.commit(`${this.resourceName}/entity/initialize`);

    this.input = JSON.parse(
      JSON.stringify(
        this.$store.getters[`${this.resourceName}/entity/initialEntity`]
      )
    );
  }
};
</script>

<style module lang="scss">
$base-header-height: 50px;
$base-footer-height: 64px;

.container.container {
  width: 100%;
  height: 100%;
  padding: 0;

  .container-header {
    height: $base-header-height !important;
  }

  .container-content {
    height: calc(100% - #{$base-header-height + $base-footer-height});
    padding: 30px;
    overflow-y: auto;
    position: relative;
    .container-error {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      p {
        color: #ff0000;
        i {
          margin: 0 10px 0 0;
        }
        i,
        span {
          vertical-align: middle;
        }
      }
    }
  }

  .container-footer {
    height: $base-footer-height !important;
  }
}
</style>
